import handleBookaDemoClick from '../../constants/bookAdemoClick';
import './HomePageHeroTitle.css';

const HomePageHeroTitle = ({ heroTitle, heroDescription, heroYoutube, heroLink }) => {
  return (
    <section className="releva-homepage-child">
      <div className="frame-container">
        <div className="frame-wrapper">
          <div className="supercharge-your-ecommerce-pro-parent">
            <h1 className="supercharge-your-ecommerce" dangerouslySetInnerHTML={{ __html: heroTitle }}></h1>
            <div className="the-only-platform-merging-auto-wrapper">
              <div className="the-only-platform" dangerouslySetInnerHTML={{ __html: heroDescription }}></div>
            </div>
            <div className="network-switch">
              {heroLink !== 'false' && (
                <button className="button2">
                  <a
                    rel="noopener noreferrer"
                    className="button-name2"
                    target="_blank"
                    href="https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-HomePage"
                    onClick={(e) =>
                      handleBookaDemoClick(
                        e,
                        'https://relevaad.zohobookings.eu/#/187906000000034054?utm_source=releva-website&utm_medium=website&utm_campaign=book-a-demo&utm_content=book-a-demo-button-SocialMediaPage'
                      )
                    }
                  >
                    Book a demo
                  </a>
                </button>
              )}
              {heroYoutube && (
                <button className="button2 youtubeButton">
                  <a
                    className="button-name2 youtubeButton"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://youtu.be/hvMC3y7pMq0"
                  >
                    Watch Overview
                  </a>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageHeroTitle;

import './ProblemStrategyResults.css';

const ProblemStrategyResults = () => {
  return (
    <section className="frame-parent141" id="releva-vision">
      <div className="elements1">
        <div className="text-1">
          <img className="icon-011" loading="lazy" alt="" src="/icon-01.svg" />
          <div className="text4">
            <div className="new-market-niche">Problem</div>
            <div className="through-generative-personaliza-container">
              <p className="we-use-real-time">
                HumanPet struggled to turn website visitors into repeat buyers and needed better personalization based
                on customers' pets.
              </p>
              <p className="blank-line2">&nbsp;</p>
            </div>
          </div>
        </div>
        <div className="text-2">
          <img className="icon-021" loading="lazy" alt="" src="/icon-02.svg" />
          <div className="text5">
            <div className="banner1">
              <div className="actions-based-on">Strategy</div>
              <div className="we-use-real-time">
                HumanPet used Releva to create targeted emails, segment customers by pet type, and track behavior for
                better targeting.
              </div>
            </div>
          </div>
        </div>
        <div className="text-3">
          <img className="icon-03" loading="lazy" alt="" src="/icon-03.svg" />
          <div className="text6">
            <div className="exceptional-experience-more">Results</div>
            <div className="we-use-real-time">
              With Releva’s, HumanPet achieved higher sales, better customer retention, improved conversion rates, and
              lower costs.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProblemStrategyResults;

import { useMemo } from 'react';
import './TrustedCustomers.css';

const TrustedCustomers = ({ block8AlignSelf, block8Padding, block8Flex, trustedBy400Margin }) => {
  const block8Style = useMemo(() => {
    return {
      alignSelf: block8AlignSelf,
      padding: block8Padding,
      flex: block8Flex,
    };
  }, [block8AlignSelf, block8Padding, block8Flex]);

  const trustedBy400Style = useMemo(() => {
    return {
      margin: trustedBy400Margin,
    };
  }, [trustedBy400Margin]);

  return (
    <div className="block-8" style={block8Style}>
      <div className="block-8-inner">
        <div className="trusted-by-400-ecommerce-bran-parent">
          <div className="trusted-by-400" style={trustedBy400Style}>
            Trusted by 500+ online brands
          </div>
          <div className="from-diverse-industries-and-gl-wrapper">
            <div className="from-diverse-industries1">
              From diverse industries and global locations, businesses rely on Releva to drive results
            </div>
          </div>
        </div>
      </div>
      <div className="data-gatherer-parent">
        <div className="data-gatherer">
          <img loading="lazy" className="data-gatherer-child" alt="" src="/logical-operators.svg" />
          <img className="imoteka-icon3" loading="lazy" alt="" src="/110.png" />
        </div>
        <img className="ebag-icon3" loading="lazy" alt="" src="/ebag1.svg" />
        <img className="trendo-icon3" loading="lazy" alt="" src="/trendo1.svg" />
        <img className="adres-icon3" loading="lazy" alt="" src="/adres1.svg" />
        <div className="output-generator">
          <img className="mercari-icon3" loading="lazy" alt="" src="/mercari1.svg" />
          <div className="function-applier1">
            <img loading="lazy" className="button-instance-icon" alt="" src="/error-handling-structures.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedCustomers;

import './InputField.css';

const InputField = () => {
  return (
    <section className="input-field5">
      <div className="dropdown-menu1">
        <div className="progress-bar2">
          <div className="table-cell">
            <div className="trusted-by-4001">Trusted by 500+ online brands</div>
            <div className="from-diverse-industries-and-gl-container">
              <div className="from-diverse-industries2">
                From diverse industries and global locations, businesses rely on Releva to drive results
              </div>
            </div>
          </div>
        </div>
        <div className="slider-parent">
          {/* <div className="slider1">
            <img
              className="modal-dialog-icon"
              alt=""
              src="/logical-operators.svg"
            />
            <img className="imoteka-icon4" alt="" src="/imoteka1.svg" />
          </div> */}
          <img loading="lazy" className="ebag-icon4" alt="" src="/ebag1.svg" />
          <img loading="lazy" className="trendo-icon4" alt="" src="/trendo1.svg" />
          <img loading="lazy" className="adres-icon4" alt="" src="/adres1.svg" />
          <div className="steps1">
            <img loading="lazy" className="mercari-icon4" alt="" src="/mercari1.svg" />
            {/* <div className="form-fields">
              <img
                className="tooltip-icon"
                alt=""
                src="/error-handling-structures.svg"
              />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default InputField;

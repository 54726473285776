import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import DescribingSectionsRight from './DescribingSectionsRight';

const SolutionsSlider = ({ version }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  let testimonials = [];
  if (version === 'two') {
    testimonials = [
      {
        title: 'Smart Search',
        imageUrl: '/Solutions/slider1.png',
        designation: `Releva's intelligent search understands what pet parents are looking for as they type, instantly showing the most relevant products. 
Whether they're searching for specific brands, life stages, or dietary needs, your customers find exactly what they need in seconds.`,
      },
      {
        title: 'Personalized Recommendations',
        imageUrl: '/Solutions/slider2.png',
        designation: `Give every pet parent a unique shopping experience with smart product suggestions. Show them the right food, treats, toys, and supplies based on their pets' needs and preferences—creating a tailored experience that builds loyalty while respecting their privacy.`,
      },
      {
        title: 'Loyalty Program',
        imageUrl: '/Solutions/slider3.png',
        designation: `Build an effective loyalty program, which is essential for cultivating lasting relationships and fostering brand allegiance.Transform first-time buyers into brand advocates with proven strategies, personalized rewards, and data-driven insights.`,
      },
      {
        title: 'Surveys',
        imageUrl: '/Solutions/slider4.png',
        designation: `Survey Creator allows you to design beautiful surveys and forms visually, ensuring a seamless user experience and meaningful insights.Send links to customers and build surveys that you can populate and analyze the results.`,
      },
      // Add more testimonials as needed
    ];
  } else {
    testimonials = [
      {
        title: 'Pet Profile Analysis',
        imageUrl: '/Solutions/slider5.png',
        designation: `Tracks pet age, breed, health needs."German Shepherd, 7 years old, joint sensitivity"<br/><h1 class="engagement-conversion">Predictive Care</h1> Anticipates pet care needs.<br/>"Time for dental cleaning supplies for Max"`,
      },
      {
        title: 'Care Pattern Learning',
        imageUrl: '/Solutions/slider6.png',
        designation: `Adapts to pet and owner habits.<br/>"Monthly premium food delivery schedule"<h1 class="engagement-conversion">Loyalty Building</h1>Strengthens pet parent relationship.<br/>"Birthday treats for your furry friend"`,
      },
      {
        title: 'Smart Recommendations',
        imageUrl: '/Solutions/slider7.png',
        designation: `Personalized product suggestions.</br>"Premium senior food + joint supplements bundle"<h1 class="engagement-conversion">Multi-channel Care</h1>Seamless communication across platforms.<br/>"Vaccine reminder via preferred channel"`,
      },
    ];
  }
  return (
    <div className={`releva-homepage-child sliderHolder ${version}`}>
      <div className={`slider-container solutionsSlider ${version}`}>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <DescribingSectionsRight
              key={index}
              title={testimonial.title}
              descriptions={testimonial.designation}
              image={testimonial.imageUrl}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SolutionsSlider;

import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigationType } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import AboutUS from './pages/AboutUS';
import CaseStudies from './pages/CaseStudies';
import CaseStudiesListing from './pages/CaseStudiesListing';
import ContentLibrary from './pages/ContentLibrary';
import ContentLibraryItem from './pages/ContentLibraryItem';
import CookiePolicy from './pages/CookiePolicy';
import CustomerAndProductPage from './pages/CustomerAndProductPage';
import DataDrivenMarketers from './pages/DataDrivenMarketers';
import LockedCaseStudy from './pages/LockedCaseStudy';
import MailAndPush from './pages/MailAndPush';
import MessageHub from './pages/MessageHub';
import NotFoundPage from './pages/NotFoundPage';
import OmniPersonalization from './pages/OmniPersonalization';
import Overview from './pages/Overview';
import Partners from './pages/Partners';
import AIHubProduct from './pages/plans/AIHubProduct';
import BrandedUrlShortenerProduct from './pages/plans/BrandedUrlShortenerProduct';
import CustomerAndProductPlatformProduct from './pages/plans/CustomerAndProductPlatformProduct';
import FlagShipProduct from './pages/plans/FlagShipProduct';
import MailAndPushProduct from './pages/plans/MailAndPushProduct';
import MessageHubProduct from './pages/plans/MessageHubProduct';
import OmniPersonalizationProduct from './pages/plans/OmniPersonalizationProduct';
import PostPurchaseProduct from './pages/plans/PostPurchaseProduct';
import SocialMediaProduct from './pages/plans/SocialMediaProduct';
import PricingPage from './pages/PricingPage';
import PricingShopifyPage from './pages/PricingsShopify';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PrivacyPolicyShopifyAppTwo from './pages/PrivacyPolicyShopifyAppTwo';
import ProductPage from './pages/ProductPage';
import RelevaAIHub from './pages/RelevaAIHub';
import RelevaHomepage from './pages/RelevaHomepage';
import SocialMedia from './pages/SocialMedia';
import SolutionsPets from './pages/SolutionsPets';
import TermsOfUse from './pages/TermsOfUse';
import TermsOfUseBg from './pages/TermsOfUseBg';
import TrendoCaseStudy from './pages/TrendoCaseStudy';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  // Scroll to top on navigation
  useEffect(() => {
    if (action !== 'POP' && location.hash === '') {
      window.scrollTo(0, 0);
    }
  }, [action, pathname, location]);

  // Function to generate or fetch token based on the page
  const getPageToken = (path) => {
    const tokenMap = {
      '/': 'd49cec71-6f0d-4961-843b-64272b7172a5',
      '/case-studies-details': '5f57fd28-2ce8-4229-8b5e-2192c77ea63e',
      '/product-page': '74716e0a-f943-4be9-861a-2c89764783ad',
      '/product': '74716e0a-f943-4be9-861a-2c89764783ad',
      '/data-driven-marketers': 'adc29653-afe6-4957-840b-26313bbf9127',
      '/overview': '5b728d2c-e22a-4f31-a05c-7d0cae1ed084',
      '/pet-shops-solutions': 'acff42aa-6719-4dac-8e41-82917fe46aca',
      '/locked-case-study': '5f57fd28-2ce8-4229-8b5e-2192c77ea63e',
      '/trendo-case-study': '5f57fd28-2ce8-4229-8b5e-2192c77ea63e',
      '/about-us': '04b41b4d-f079-4433-8bdb-69bb40a76001',
      '/terms-of-use': '20766ce2-b657-4a16-a4a7-7e4bf9f1c87c',
      '/cookie-policy': '20766ce2-b657-4a16-a4a7-7e4bf9f1c87c',
      '/privacy-policy': '20766ce2-b657-4a16-a4a7-7e4bf9f1c87c',
      '/ai-hub': '204e9000-b477-4e7b-b153-4c49e2cbb057',
      '/omni-personalization': '5a9e9c5e-fec3-43ad-b31a-149dfb25eb77',
      '/mail-and-push': '28f179c8-2713-4fef-90e5-bca0b560d39a',
      '/message-hub': '44598b14-23cb-4dbf-9301-79a90525d33e',
      '/social-media': '3f6a9d1b-eba1-4bfa-aab6-d81a43553ad5',
      '/customer-and-product-platform': '7bfa6a7e-c921-4e09-aadf-343c9c691308',
      '/pricing': '08e72441-3370-4b9c-ad0e-f8a82cb02638',
      '/pricing-2': '08e72441-3370-4b9c-ad0e-f8a82cb02638',
      '/merchant-pro-integration': '63c5e0d0-9e18-4ba4-ba72-41248af4eda9',
      '/case-studies': '5f57fd28-2ce8-4229-8b5e-2192c77ea63e',
      '/privacy-policy-app': '04b41b4d-f079-4433-8bdb-69bb40a76001',
      '/plans/flagship-product': 'e29a0c51-c3f5-4089-9323-de3c32763cab',
      '/plans/ai-hub-product': 'e29a0c51-c3f5-4089-9323-de3c32763cab',
      '/plans/omni-personalization-product': 'e29a0c51-c3f5-4089-9323-de3c32763cab',
      '/plans/mail-and-push-product': 'e29a0c51-c3f5-4089-9323-de3c32763cab',
      '/plans/message-hub-product': 'e29a0c51-c3f5-4089-9323-de3c32763cab',
      '/plans/social-media-product': 'e29a0c51-c3f5-4089-9323-de3c32763cab',
      '/plans/post-purchase-product': 'e29a0c51-c3f5-4089-9323-de3c32763cab',
      '/plans/customer-and-product-platform-product': 'e29a0c51-c3f5-4089-9323-de3c32763cab',
      '/plans/branded-url-shortener': 'e29a0c51-c3f5-4089-9323-de3c32763cab',
      '/content-library': '45fa3854-4da2-46ec-877d-247ae16371a4',
      '/content-library-details': '45fa3854-4da2-46ec-877d-247ae16371a4',
      '/partners': '1336cd09-b745-4259-9b3a-546d556aa591',
      default: 'd49cec71-6f0d-4961-843b-64272b7172a5',
    };
    return tokenMap[path] || tokenMap['default'];
  };

  // Load Releva script only once when the app is first loaded
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://releva.ai/sdk/v0/js/releva-sdk-js.min.js';
    script.async = true;
    script.onload = () => {
      document.dispatchEvent(new Event('relevaSdkLoaded'));
    };
    document.body.appendChild(script);

    // Clean up when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array to ensure this runs only once

  // Update the page token whenever the page changes
  useEffect(() => {
    const pageToken = getPageToken(pathname);

    // Function to safely use Releva SDK after it has loaded
    const handleRelevaSdkLoaded = () => {
      if (window.Releva) {
        window.Releva.push(
          'a380e041-f8e4-49e8-a647-85c365f11a2a',
          {
            page: {
              token: pageToken,
            },
          },
          function (results) {
            //console.log(results);
          },
          function (error) {
            console.error(error);
          },
          {
            serviceWorkerUrl: '/service-worker.min.js',
          }
        );
      } else {
        console.error('Releva SDK not available.');
      }
    };

    // Check if Releva SDK is already loaded, if yes, update the token
    if (window.Releva) {
      handleRelevaSdkLoaded();
    } else {
      // Add event listener for when the SDK is loaded
      document.addEventListener('relevaSdkLoaded', handleRelevaSdkLoaded);
    }

    // Cleanup the event listener to avoid duplicates
    return () => {
      document.removeEventListener('relevaSdkLoaded', handleRelevaSdkLoaded);
    };
  }, [pathname]); // Run this effect whenever the pathname changes

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<RelevaHomepage />} />
        <Route path="/case-studies-details" element={<CaseStudies />} />
        <Route path="/product-page" element={<ProductPage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/data-driven-marketers" element={<DataDrivenMarketers />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/pet-shops-solutions" element={<SolutionsPets />} />
        <Route path="/locked-case-study" element={<LockedCaseStudy />} />
        <Route path="/trendo-case-study" element={<TrendoCaseStudy />} />
        <Route path="/about-us" element={<AboutUS />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/ai-hub" element={<RelevaAIHub />} />
        <Route path="/omni-personalization" element={<OmniPersonalization />} />
        <Route path="/mail-and-push" element={<MailAndPush />} />
        <Route path="/message-hub" element={<MessageHub />} />
        <Route path="/social-media" element={<SocialMedia />} />
        <Route path="/customer-and-product-platform" element={<CustomerAndProductPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/pricing-2" element={<PricingShopifyPage />} />
        <Route path="/merchant-pro-integration" element={<PricingPage />} />
        <Route path="/case-studies" element={<CaseStudiesListing />} />
        <Route path="/privacy-policy-app" element={<PrivacyPolicyShopifyAppTwo />} />
        <Route path="/plans/flagship-product" element={<FlagShipProduct />} />
        <Route path="/plans/ai-hub-product" element={<AIHubProduct />} />
        <Route path="/plans/omni-personalization-product" element={<OmniPersonalizationProduct />} />
        <Route path="/plans/mail-and-push-product" element={<MailAndPushProduct />} />
        <Route path="/plans/message-hub-product" element={<MessageHubProduct />} />
        <Route path="/plans/social-media-product" element={<SocialMediaProduct />} />
        <Route path="/plans/post-purchase-product" element={<PostPurchaseProduct />} />
        <Route path="/plans/customer-and-product-platform-product" element={<CustomerAndProductPlatformProduct />} />
        <Route path="/plans/branded-url-shortener" element={<BrandedUrlShortenerProduct />} />
        <Route path="/content-library" element={<ContentLibrary />} />
        <Route path="/content-library-details" element={<ContentLibraryItem />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/terms-of-use-bg" element={<TermsOfUseBg />} />
        <Route path="/общи-условия" element={<TermsOfUseBg />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;

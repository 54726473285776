import { Helmet } from 'react-helmet';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import DescribingSectionsLeft from '../components/Solutions/DescribingSectionsLeft';
import DescribingSectionsRight from '../components/Solutions/DescribingSectionsRight';
import ProblemStrategyResults from '../components/Solutions/ProblemStrategyResults';
import SolutionsSlider from '../components/Solutions/SolutionsSlider';
import Testimonials from '../components/Testimonials';
import TrustedCustomersPurple from '../components/TrustedCustomersPurple';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CaseStudies.css';
import './Overview.css';

const SolutionsPets = () => {
  const achievedResults = [
    { key: 'Open rate', value: '59.7' },
    { key: 'Click rate', value: '32.5' },
    { key: 'Conversion Rate', value: '6.2' },
    { key: 'Unsubscribed rate', value: '0.1' },
  ];
  return (
    <div className="overview solutions">
      <Helmet>
        <title>Releva AI | Pet Shops Solutions</title>
        <meta
          name="description"
          content="Explore Releva AI's comprehensive overview. Learn how our AI-driven marketing platform can transform your business with personalized customer experiences, data-driven insights, and automated campaigns. Discover the future of marketing with Releva AI."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.solutionsPets)}</script>
      </Helmet>
      <div className="solutionsHero">
        <HomePageHeroTitle
          heroTitle="Automate Your Pet Store Marketing With All-In-One AI Platform<br/><br/>Powered by AI Vet Consultant"
          heroDescription="Make your pet store the go-to place for trusted pet care by combining expert knowledge with AI. Give customers personalized advice and product suggestions anytime through your website, app, and other channels."
          heroYoutube={false}
        />
        <section className="symbol-library">
          <img className="image-118-icon hero-image" loading="lazy" alt="" src="/Solutions/Frame0.png" />
        </section>
      </div>
      <TrustedCustomersPurple />
      <DescribingSectionsLeft
        className="logic-gate12 first"
        title="Smart Growth Strategies for Pet Retailers"
        descriptions="<p>Connect your pet expertise with AI-powered insights to grow your business effortlessly. Launch intelligent promotions, create perfect product bundles, and deliver expert recommendations—all automatically. No technical expertise needed.</p>
<ul><li>Boost sales with veterinary-backed product bundles</li><li>Recommend the right food based on pet profiles</li><li>Match accessories to pet age, size, and lifestyle</li><li>Guide pet parents to ideal health products</li><li>Build lasting relationships with pet families</li><li>Recover sales with timely, relevant reminders</li></ul>"
        image="/Solutions/Frame1.png"
      />
      <Testimonials />
      <br />
      <DescribingSectionsRight
        title="Your 24/7 Veterinary Sales Consultant"
        descriptions="<p>Our AI combines veterinary knowledge with retail expertise, understanding not just products, but pets and their parents. It automatically considers pet age, breed, weight, activity level, and health needs alongside owner preferences and shopping patterns to deliver perfect recommendations.</p>"
        image="/Solutions/Frame2.png"
      />
      <div className="solidPurpleBckg">
        <DescribingSectionsRight
          title="How Human Pet achieved 75% open rate in workflows thanks to Releva’s omnichannel marketing solution"
          descriptions={`<p>"Our biggest problem was that we didn't know our audience and we couldn't target them with the right products, and that was our most important goal."</p><br/><p>CEO at Human Pet</p><p><a href="https://releva.ai/#/case-studies-details?case-study=humanpet">Read the full story</a></p>`}
          image="/Solutions/Frame3.jpg"
        />
        <ProblemStrategyResults />
      </div>
      <SolutionsSlider version="one" />
      <HomePageHeroTitle
        heroTitle="Understand Every Pet and Owner"
        heroDescription="See the complete picture—from pet profiles to shopping patterns. Know exactly what food, treats, and supplies each pet needs based on their unique characteristics. Understand when owners typically purchase, their budget preferences, and how they make decisions."
        heroLink="false"
      />
      <section className="symbol-library">
        <img className="image-118-icon hero-image" loading="lazy" alt="" src="/Solutions/Frame4.png" />
      </section>
      <HomePageHeroTitle
        heroTitle="Deliver Expert Guidance Across All Channels"
        heroDescription="Provide consistent, veterinary-backed recommendations wherever pet parents shop—website, email, or messages. Help them find the right products for their pets' specific needs, automatically adjusting recommendations as pets grow and needs change."
        heroLink="false"
      />
      <section className="symbol-library">
        <img className="image-118-icon hero-image" loading="lazy" alt="" src="/Solutions/Frame5.png" />
      </section>
      <SolutionsSlider version="two" />
      <HomePageHeroTitle
        heroTitle="Create Personal Connections at Scale"
        heroDescription="Transform pet parent data into meaningful relationships. Whether it's suggesting the right puppy food or timing flea treatment reminders, deliver the personalized care experience pet parents expect from a trusted pet health partner."
        heroLink="false"
      />
      <section className="symbol-library">
        <img className="image-118-icon hero-image" loading="lazy" alt="" src="/Solutions/Frame6.png" />
      </section>
      <div className="social-proof-section-child">
        <div className="section-01 ivory frame-parent4">
          <h1 className="resultsHeading">Releva Email Marketing Benchmarks</h1>
          {achievedResults.map((result) => (
            <div className="frame-parent5" key={result.key}>
              <div className="parent">
                <div className="div">{result.value}</div>
                <div className="wrapper">
                  <h1 className="h11">%</h1>
                </div>
              </div>
              <div className="increase-in-conversion-rates-wrapper">
                <div className="increase-in-conversion">{result.key}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SolutionsPets;

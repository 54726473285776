import './DescribingSections.css';

const DescribingSectionsRight = ({ title, descriptions, image }) => {
  return (
    <section className="logic-gate12">
      <div className="frame-parent96">
        <div className="i-11">
          <img loading="lazy" src={image} alt={title} title={title} className="HomePageHeroImage" />
        </div>
        <div className="frame-wrapper37">
          <div className="engagement-conversion-parent">
            <h1 className="engagement-conversion">{title}</h1>
            <div className="unlocking-ecommerce-success" dangerouslySetInnerHTML={{ __html: descriptions }}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DescribingSectionsRight;

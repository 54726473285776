import './IterativeStructure.css';

const IterativeStructure = () => {
  return (
    <section className="iterative-structure">
      <div className="sub-menu">
        <div className="menu-items">
          <div className="how-it-works-wrapper">
            <div className="how-it-works2">How it works</div>
          </div>
          <div className="integrations-wrapper">
            <a rel="noopener noreferrer" href="#/overview#integration">
              <div className="integrations1">Integrations</div>
            </a>
          </div>
          <div className="solutions-wrapper">
            <a rel="noopener noreferrer" href="#/overview#solutions">
              <div className="solutions1">Solutions</div>
            </a>
          </div>
          <div className="results-wrapper">
            <a rel="noopener noreferrer" href="#/overview#results">
              <div className="results1">Results</div>
            </a>
          </div>
          <div className="faq-wrapper">
            <a href="#/overview#faq" rel="noopener noreferrer">
              <div className="faq1">FAQ</div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IterativeStructure;

import { useMemo } from 'react';
import './TrustedCustomers.css';

const TrustedCustomersPurple = ({ block8AlignSelf, block8Padding, block8Flex, trustedBy400Margin }) => {
  const block8Style = useMemo(() => {
    return {
      alignSelf: block8AlignSelf,
      padding: block8Padding,
      flex: block8Flex,
    };
  }, [block8AlignSelf, block8Padding, block8Flex]);

  const trustedBy400Style = useMemo(() => {
    return {
      margin: trustedBy400Margin,
    };
  }, [trustedBy400Margin]);

  return (
    <div className="block-8 trustedCustomersPurple" style={block8Style}>
      <div className="block-8-inner">
        <div className="trusted-by-400-ecommerce-bran-parent">
          <div className="trusted-by-400" style={trustedBy400Style}>
            Trusted by 400+ eCommerce brands
          </div>
          <div className="from-diverse-industries-and-gl-wrapper">
            <div className="from-diverse-industries1">
              From diverse industries and global locations, businesses rely on Releva to drive results
            </div>
          </div>
        </div>
      </div>
      <div className="data-gatherer-parent">
        <img className="imoteka-icon3" loading="lazy" alt="" src="/zoomagazin.png" />
        <img className="imoteka-icon3" loading="lazy" alt="" src="/test.png" />
        <img className="trendo-icon3" loading="lazy" alt="" src="/test2.png" />
        <img className="adres-icon3" loading="lazy" alt="" src="/trendowhite.svg" />
        <img className="mercari-icon3" loading="lazy" alt="" src="ardeswhite.svg" />
      </div>
    </div>
  );
};

export default TrustedCustomersPurple;
